body {

    background-color: #ffffff;
    font-family: Nunito,sans-serif;
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
  
  }
  
  .logout {
  
    font-size: 16px;
    font-family: auto;
      background: #f11616;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 6px 21px 6px 21px;
      justify-content: center;
      font-weight: bold;
      border-radius: 5px;
      margin: 0 22px 0 0;
      cursor: pointer;
  }
  
  
  .popup-arrow {
    color: rgb(253, 253, 253);
  }
  
  .bg-img {
    display: block;
    background-size: 40px;
    height: 40px;
    width: 40px;
    background-repeat: no-repeat;
    margin: 0px 5px 0px 0px;
  }
  
  .content-section-tab-area {
  
     display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 24px 0 0 0;
  }
  
  @media (max-width: 1037px) {
  
      .content-section-tab-area{ padding: 4px 0 18px 0;}
  }
  
  @media (max-width: 750px) {
  
      .content-section-tab-area{ height: 100px;}
  }
  
  .background_service_option_disp_area {
  
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: bold;
    padding: 0px 10px 0px 0px;
    color: black;
  }
  
  @media (max-width: 695px) {
  
    .background_service_option_disp_area{ margin: 10px 0 0 0;}
  }
  
  .tabs_area {
  
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 28px;
    margin: 0px 20px 0px 10px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .welcome-div {
  
    background-color: #ffffff;
    box-shadow: 0 1px 4px -3px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .welcome-main-div {
    display: flex;
    align-items: center;
    max-width: 200px;
    padding: 15px;
    border-width: 0 1px;
  }
  
  .welcome-text-div {
  
    display: flex;
    flex-direction: column;
    color: #3d9ac9;
    font-weight: bold;
  }
  
  .welcome-text-span {
  
     font-size: 18px;
    font-weight: bold;
    color: #000;
  }
  
  .welcome-user-name-span {
  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 0 24px;
    font-size: 18px;
    color: #7e1a1a;
  }
  
  .content-area {
  
    background-color: #ffffff;
    height: 100%;
  }
  
  .content-section-one-area {
  
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px 20px 15px 10px;
  }
  
  .content-section-two-area {
  
    height: 40px;
    display: flex;
    align-items: center;
    padding: 15px 10px 0px 10px;
    justify-content: space-between;
  }
  
  .buttonstyle {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 6px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    background-color: #008CBA;
    font-weight: 600;
    border-radius: 5px;
  }
  
  .did_call_section_title {
  
    font-size: 20px;
    font-weight: bold;
    color: black;
    font-style: italic;
  }
  
  .buttontype {
  
    background-color: #6C757D;
    padding: 8px 10px 8px 10px;
    width: 5%;
    color: white;
    text-align: center;
    font-size: 17px;
    box-shadow: 0 2px 6px 0 rgb(108 117 125 / 50%);
    font-weight: 600;
  }
  
  .active-tab {
  
    margin: 1px 5px 1px 1px;
    padding:0px 10px 0px 10px;
    color: #7e1a1a;
    font-size: 1.40em;
    text-shadow: 0px 0.3px 0px #b02a30;
    font-family: ZurichBT-Bold;
    cursor: pointer;
  }
  
  .active-tab:hover {
  
    background-color: #0acf97;
    color: white;
  }
  
  .inactive-tab {
  
    margin: 1px 5px 1px 1px;
    padding:0px 10px 0px 10px;
    color: #868b8f;
    font-size: 1.40em;
    font-family: ZurichBT-Bold;
    text-shadow: 0px 0.3px 0px #b02a30;
    cursor: pointer;
  }
  
  .inactive-tab:hover {
  
    background-color: #0acf97;
    color: white;
  }
  
  .span-divider {
    width: 1px;
    height: 30px;
    background-color: rgb(128, 103, 103);
    padding-left: 1px;
  }
  
  .options {
    border: 1px solid #b8b0b0;
    padding: 8px 0px 8px 8px;
    width: 50%;
  }
  
  select {
    font-size: 14px;
    border: none;
    width: 20%;
    background: rgb(243, 241, 241);
  }
  
  .inputtext {
  
    width: 20%;
    background: transparent;
    border: none;
    height: 30px;
    border-bottom: 1px solid #7c7979;
  }
  
  .labeltext {
      width: 10%;
      color: rgb(3, 0, 0);
      font-size: 17px;
      font-family: ZurichBT-Bold;
  }
  
  .add-phnumber-div {
    display: flex;
    justify-content: left;
  }
  
  .search-div {
  
    display: flex;
    justify-content: end;
    position: relative;
    margin-right: 10px;
  }
  
  .search-div input {
  
    background-color: #f7f5f5;
    border: 1px solid #ddd;
    padding: 8px;
  }
  /*
  .search-icon {
  
    display: block;
    background-image: url(./search.png);
    background-size: 25px;
    height: 25px;
    width: 25px;
    margin: 0px 5px 0px 0px;
    position: absolute;
    top: 4px;
    right: -4px;
    z-index: 100;
  }*/
  
  .searchbutton {
    float: left;
      width: 22%;
      padding: 6px;
      background: #2196F3;
      color: white;
      font-size: 14px;
      border: 1px solid #2196F3;
      border-left: none;
      cursor: pointer;
      margin-left: 5px;
      margin-right: 5px;
      font-weight: bold;
      border-radius: 5px;
  }
  
  searchbutton:hover {
    background: #0b7dda;
  }
  
  
  
  .add-phnumber-input-button {
  
    background-color: #6c757d;
    border: 1px solid #6c757d;
    padding: 12px;
    color: #ffffff;
    cursor: pointer;
    padding: 8px 15px 8px 15px;
    margin-left: 20px;
    font-weight: bold;
  }
  
  
  
  .add-did-area {
  
    //padding: 3px 0px 0px 20px;
  }
  
  .did-input-button {
  
    border: 1px solid #ddd;
    width: 97%;
    padding: 0.45rem 0.9rem;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
    background-color: #fff;
    background-clip: padding-box;
  }
  
  .row {
    margin-top: 20px;
  }
  
  .row label {
    margin-bottom: 10px;
    display: inline-block;
  }
  
  .save-button {
  
    padding: 5px;
    background-color: #0acf97;
    border-color: #0acf97;
    color: white;
    box-shadow: 0 2px 6px 0 rgb(10 207 151 / 50%);
    border: 1px solid transparent;
    padding: 0.45rem 0.9rem;
    font-size: .9rem;
    line-height: 1.5;
    border-radius: 0.15rem;
    cursor: pointer;
  }
  
  .cancel-button {
  
    padding: 5px;
    background-color: #fa5c7c;
    border-color: #fa5c7c;
    color: white;
    box-shadow: 0 2px 6px 0 rgb(10 207 151 / 50%);
    border: 1px solid transparent;
    padding: 0.45rem 0.9rem;
    font-size: .9rem;
    line-height: 1.5;
    border-radius: 0.15rem;
    cursor: pointer;
  }
  
  .divider {
  
    background-color: thistle;
    height: 1px;
    margin: 10px 20px 13px 20px;
  }
  
  .call-setup-area {
  
    //padding: 3px 0px 0px 20px;
  }
  
  .table-area {
  
    width: 98%;
    display: flex;
    justify-content: center;
    margin: 0px 15px 0px 15px;
  }
  
  .scrollContent tr:nth-child(even) {
  
  
  background:#f2f2f2;
  }
  .mainfont{ font-size:36px;  font-family: myFirstFont;}
  
  div {
  font-family: myFirstFont;
  }
  
  table, td, a {
  color: #000;
  font: normal normal 12px Verdana, Geneva, Arial, Helvetica, sans-serif;
  }
  
  div.tableContainer {
  clear: both;
  overflow-y: hidden;
  overflow: auto;
  width: 100%;
  }
  
  
  div.tableContainer {
  overflow: auto;
  overflow-y: hidden;
  width:100%;
  }
  
  
  
  
  
  
  thead.fixedHeader tr {
  position: relative;
  }
  
  
  thead.fixedHeader th {
  
  /*border-right: 1px solid #dbdbdb;
  /*border-top: 1px solid #EB8;*/
  font-weight: normal;
      word-break: break-all;
  text-align: left; background:#f2f2f2;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  }
  
  html>body tbody.scrollContent {
  display: block;
  height: calc(100vh - 350px);
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
  }
  
  html>body thead.fixedHeader {
  display: table;
  overflow: auto;
  }
  
  
  tbody.scrollContent td, tbody.scrollContent tr.normalRow td {
  /*background: #FFF;*/
  border-bottom: none;
  border-left: none;
  font-size: 14px;
  }
  
  
  
  tbody.scrollContent tr.alternateRow td {
  background: #EEE;
  border-bottom: none;
  border-left: none;
  border-right: 1px solid #CCC;
  border-top: 1px solid #DDD;
  padding: 2px 3px 3px 4px
  }
  .tablewidth{width: 187px; min-width: 160px;padding: 25px 0px 25px 0px;text-align: center;}
  .smalltablewidth{width: 187px; min-width: 170px;padding: 25px 0px 25px 0px;}
  /*.smalltablewidth{width: 120px; min-width: 110px;padding: 25px 0px 25px 0px;}*/
  .w_100per{ width:100%;}
  
  .pagination-areas {
  
    display: flex;
    justify-content: right;
    padding: 20px 30px 0px 0px;
  }
  
  .pagination-next-prev-button {
  
    font-weight: bold ;
    font-size: 13;
  }
  
  .pagination {
    display: inline-block;
  }
  
  .pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
  }
  
  .pagination a.active {
    background-color: #4CAF50;
    color: white;
  }
  
  .pagination a:hover:not(.active) {background-color: #ddd;}
  
  .display-flex {
      display: flex;
      align-items: center;
  }
  
  .participants-search {
  
    display: flex;
    align-items: center;
  }
  
  .from-to-date-area {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .from-to-text {
  
    color: black;
    font-weight: bold;
    margin-right: 5px;
    margin-left: 5px;
  }
  
  .go-button {
  
    background-color: #4CAF50;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    font-weight: bold;
    margin-right: 5px;
    margin-left: 5px;
    padding: 6px;
    border-radius: 5px;
  }
  
  .editbutton {
  
    color:darkgreen;
    vertical-align: middle;
    font-size:18px;
    cursor: pointer;
  }
  
  .deletebutton {
  
    font-size:17px;
    color:red;
    vertical-align: middle;
    font-size:20px;
    cursor: pointer;
  }
  
  .savebutton {
  
    font-size:17px;
    color:green;
    vertical-align: middle;
    font-size:20px;
    cursor: pointer;
  }
  
  .no-data-display {
  
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: red;
    padding-top: 20px;
    font-weight: bold;
  }
  
  .no-log-data-display {
  
    display: flex;
    justify-content: left;
    margin-left: 200px;
    font-size: 16px;
    color: red;
    padding-top: 20px;
    font-weight: bold;
  }
  
  
  .popup-header {
  
    background-color: #2293EE;
    color: white;
    font-size: 17px;
    padding: 10px 0px 10px 10px;
  }
  
  .popup-data-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 30px;
    height: 30px;
    -webkitanimation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  .file-upload-area {
  
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  @media (max-width: 1050) {
  
    .file-upload-area { margin: 5px 0 0 0;}
  }
  
  .background-service-area {
  
    flex-wrap: wrap;
    display: flex;
    align-items: center;
  }
  
  @media (max-width: 750px) {
  
  .background-service-area{margin: 7px 0 0 0;}
  }
  
  .input {
  
    height:20px;
  }
  
  .search-area-style {
  
     display: flex;
      align-items: center;
      flex-wrap: wrap;
  }
  
  .filter-area {
  
  display: flex;
      align-items: center;
  }
  
  @media (max-width: 950px) {
  
   .filter-area{ margin: 12px 0 0px 0;}
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  